import React from 'react';
import { FelaCSS } from '../../components/fela/flowtypes';
import { IStylingProps } from '../../themes/types';
import { TColor } from '../../types';
import IconWrap from './icon-wrap';

interface IProps extends IStylingProps {
  id?: string;
  color?: TColor;
  width?: number;
  style?: FelaCSS;
}

interface IconTypes {
  iconColor: string;
  width: number;
  height: number;
}

const IconSupplierDressmaker = ({ id, color, width = 48, style, ...restProps }: IProps) => {
  const viewBoxWidth = 24;
  const viewBoxHeight = 24;

  const icon = ({ iconColor, width, height }: IconTypes) => (
    <svg
      id={id}
      viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
      width={`${width}px`}
      height={`${height}px`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.9678 4.70001C12.3605 4.15031 12.8602 3.68557 13.4369 3.33365C14.0135 2.98173 14.6553 2.74989 15.3238 2.65201C15.4102 2.64366 15.4974 2.65766 15.5769 2.69268C15.6564 2.72771 15.7256 2.78257 15.7778 2.85201L16.4388 3.71401C16.5131 3.81112 16.5493 3.93213 16.5405 4.05412C16.5316 4.17611 16.4784 4.29063 16.3908 4.37601C15.6028 5.12901 13.6548 7.13401 13.6548 8.55701C13.6548 8.55701 19.9198 11.111 19.9198 19.641"
        stroke={iconColor ?? '#546170'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.9438 19.637C15.9438 20.1642 15.7343 20.6699 15.3615 21.0427C14.9887 21.4155 14.483 21.625 13.9558 21.625C13.4285 21.625 12.9229 21.4155 12.55 21.0427C12.1772 20.6699 11.9678 20.1642 11.9678 19.637"
        stroke={iconColor ?? '#546170'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.9198 19.637C19.9198 20.1642 19.7104 20.6699 19.3376 21.0427C18.9648 21.4155 18.4591 21.625 17.9318 21.625C17.4046 21.625 16.8989 21.4155 16.5261 21.0427C16.1533 20.6699 15.9438 20.1642 15.9438 19.637"
        stroke={iconColor ?? '#546170'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.968 4.70008C11.5759 4.14977 11.0765 3.68441 10.5 3.33196C9.92345 2.97951 9.28162 2.74723 8.61304 2.64908C8.52657 2.64073 8.43941 2.65473 8.35991 2.68975C8.28041 2.72478 8.21124 2.77964 8.15904 2.84908L7.50004 3.71008C7.42569 3.80719 7.38951 3.9282 7.39836 4.05019C7.4072 4.17218 7.46046 4.2867 7.54804 4.37208C8.33604 5.12508 10.284 7.13008 10.284 8.55308C10.284 8.55308 4.01904 11.1071 4.01904 19.6371"
        stroke={iconColor ?? '#546170'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.99318 19.637C7.98724 19.9017 8.03425 20.165 8.13147 20.4113C8.22868 20.6577 8.37413 20.8821 8.55927 21.0714C8.74442 21.2608 8.96553 21.4112 9.20961 21.5139C9.4537 21.6166 9.71586 21.6695 9.98068 21.6695C10.2455 21.6695 10.5076 21.6166 10.7517 21.5139C10.9958 21.4112 11.2169 21.2608 11.4021 21.0714C11.5872 20.8821 11.7327 20.6577 11.8299 20.4113C11.9271 20.165 11.9741 19.9017 11.9682 19.637"
        stroke={iconColor ?? '#1A2C40'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.01709 19.637C4.01709 20.1642 4.22654 20.6699 4.59936 21.0427C4.97218 21.4155 5.47784 21.625 6.00509 21.625C6.53234 21.625 7.038 21.4155 7.41082 21.0427C7.78364 20.6699 7.99309 20.1642 7.99309 19.637"
        stroke={iconColor ?? '#1A2C40'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  return (
    <IconWrap
      icon={icon}
      color={color}
      width={width}
      viewBoxWidth={viewBoxWidth}
      viewBoxHeight={viewBoxHeight}
      style={style}
      {...restProps}
    />
  );
};

export default IconSupplierDressmaker;
