import React from 'react';
import { FelaCSS } from '../../components/fela/flowtypes';
import { IStylingProps } from '../../themes/types';
import { TColor } from '../../types';
import IconWrap from './icon-wrap';

interface IProps extends IStylingProps {
  id?: string;
  color?: TColor;
  width?: number;
  style?: FelaCSS;
}

interface IconTypes {
  iconColor: string;
  width: number;
  height: number;
}

const IconSupplierDecorHire = ({ id, color, width = 48, style, ...restProps }: IProps) => {
  const viewBoxWidth = 24;
  const viewBoxHeight = 24;

  const icon = ({ iconColor, width, height }: IconTypes) => (
    <svg
      id={id}
      viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
      width={`${width}px`}
      height={`${height}px`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.3722 4.52105C14.3388 3.66008 13.9804 2.84376 13.3692 2.23645C12.758 1.62915 11.9394 1.27598 11.0782 1.24805C10.4608 1.28084 9.86716 1.49636 9.37254 1.86724C8.87792 2.23812 8.50467 2.74762 8.30019 3.33105C8.03636 2.77192 7.61232 2.3038 7.0819 1.98616C6.55149 1.66852 5.93864 1.51568 5.32119 1.54705C4.4679 1.66548 3.6911 2.10258 3.14697 2.77045C2.60284 3.43832 2.33174 4.28743 2.38819 5.14705C2.58819 8.91005 7.93719 11.7 8.73519 11.658"
        stroke={iconColor ?? '#1A2C40'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.12785 7.19997C5.47001 6.63959 5.03005 5.86584 4.88485 5.01397C4.87833 4.81527 4.92798 4.61876 5.02809 4.44701C5.12821 4.27525 5.27474 4.13521 5.45085 4.04297"
        stroke={iconColor ?? '#546170'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.8191 6.18403C18.2041 6.12724 17.586 6.2543 17.0432 6.5491C16.5004 6.84391 16.0573 7.29319 15.7701 7.84003C15.5905 7.24805 15.239 6.72283 14.7602 6.33111C14.2814 5.93938 13.6969 5.69883 13.0811 5.64003C12.2198 5.63243 11.3875 5.95138 10.7519 6.53267C10.1162 7.11396 9.72436 7.91445 9.65512 8.77303C9.30012 12.524 14.1891 16.064 14.9851 16.139C15.7811 16.214 21.2471 13.654 21.6001 9.90002C21.692 9.04384 21.4562 8.18463 20.9403 7.49522C20.4243 6.8058 19.6665 6.33733 18.8191 6.18403V6.18403Z"
        stroke={iconColor ?? '#1A2C40'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.055 11.3499C12.4868 10.6998 12.1644 9.87107 12.144 9.00788C12.1662 8.81048 12.2437 8.62334 12.3675 8.46802C12.4914 8.31269 12.6565 8.19548 12.844 8.12988"
        stroke={iconColor ?? '#546170'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.231 13.7849L11.563 22.7559"
        stroke={iconColor ?? '#546170'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.0708 22.7389L14.5648 19.3979"
        stroke={iconColor ?? '#546170'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.88 17.291C12.896 18.241 16.585 18.701 15.865 17.538"
        stroke={iconColor ?? '#1A2C40'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  return (
    <IconWrap
      icon={icon}
      color={color}
      width={width}
      viewBoxWidth={viewBoxWidth}
      viewBoxHeight={viewBoxHeight}
      style={style}
      {...restProps}
    />
  );
};

export default IconSupplierDecorHire;
