import React from 'react';
import { FelaCSS } from '../../components/fela/flowtypes';
import { IStylingProps } from '../../themes/types';
import { TColor } from '../../types';
import IconWrap from './icon-wrap';

interface IProps extends IStylingProps {
  id?: string;
  color?: TColor;
  width?: number;
  style?: FelaCSS;
}

interface IconTypes {
  iconColor: string;
  width: number;
  height: number;
}

const IconSupplierMarquee = ({ id, color, width = 48, style, ...restProps }: IProps) => {
  const viewBoxWidth = 24;
  const viewBoxHeight = 24;

  const icon = ({ iconColor, width, height }: IconTypes) => (
    <svg
      id={id}
      viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
      width={`${width}px`}
      height={`${height}px`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.4002 2.5C13.0999 2.50089 12.8065 2.59087 12.5573 2.75855C12.3081 2.92624 12.1142 3.16409 12.0002 3.442C11.886 3.16424 11.6921 2.92654 11.443 2.75889C11.1938 2.59124 10.9005 2.50115 10.6002 2.5C10.182 2.53488 9.79248 2.72699 9.51024 3.03763C9.228 3.34826 9.07398 3.75433 9.07923 4.174C9.07923 6.009 11.6112 7.5 12.0002 7.5C12.3892 7.5 14.9212 6.009 14.9212 4.174C14.9265 3.75433 14.7725 3.34826 14.4902 3.03763C14.208 2.72699 13.8185 2.53488 13.4002 2.5V2.5Z"
        stroke={iconColor ?? '#1A2C40'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.5 21.5V11.5C2.49972 9.91684 3.03609 8.38036 4.02156 7.14135C5.00703 5.90233 6.38341 5.03396 7.926 4.67798"
        stroke={iconColor ?? '#546170'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.71508 7.90503C9.71508 7.90503 9.54308 12.333 3.95508 15"
        stroke={iconColor ?? '#546170'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.50018 21.5C5.74682 19.5931 5.40601 17.5482 5.50018 15.5"
        stroke={iconColor ?? '#546170'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 13.0631C5.33062 11.6946 6.31764 10.03 6.88 8.20605"
        stroke={iconColor ?? '#546170'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.5002 21.5V11.5C21.5005 9.91684 20.9641 8.38036 19.9787 7.14135C18.9932 5.90233 17.6168 5.03396 16.0742 4.67798"
        stroke={iconColor ?? '#1A2C40'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.2852 7.90503C14.2852 7.90503 14.4572 12.333 20.0452 14.998"
        stroke={iconColor ?? '#1A2C40'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5 21.5C18.2534 19.5931 18.5942 17.5482 18.5 15.5"
        stroke={iconColor ?? '#1A2C40'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.0001 13.0631C18.6695 11.6946 17.6825 10.03 17.1201 8.20605"
        stroke={iconColor ?? '#1A2C40'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.5 21.5H9.5"
        stroke={iconColor ?? '#1A2C40'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5 21.5H13.5"
        stroke={iconColor ?? '#1A2C40'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5 21.5H22.5"
        stroke={iconColor ?? '#1A2C40'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  return (
    <IconWrap
      icon={icon}
      color={color}
      width={width}
      viewBoxWidth={viewBoxWidth}
      viewBoxHeight={viewBoxHeight}
      style={style}
      {...restProps}
    />
  );
};

export default IconSupplierMarquee;
