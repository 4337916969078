import React from 'react';
import { FelaCSS } from '../../components/fela/flowtypes';
import { IStylingProps } from '../../themes/types';
import { TColor } from '../../types';
import IconWrap from './icon-wrap';

interface IProps extends IStylingProps {
  id?: string;
  color?: TColor;
  width?: number;
  style?: FelaCSS;
}

interface IconTypes {
  iconColor: string;
  width: number;
  height: number;
}

const IconSupplierFlorist = ({ id, color, width = 48, style, ...restProps }: IProps) => {
  const viewBoxWidth = 24;
  const viewBoxHeight = 24;

  const icon = ({ iconColor, width, height }: IconTypes) => (
    <svg
      id={id}
      viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
      width={`${width}px`}
      height={`${height}px`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.8791 5.66811C15.5061 6.84211 14.0661 7.17411 11.3741 6.89211C8.27407 6.56911 6.40107 5.07011 7.17407 3.62711C7.79207 2.47311 10.2991 1.77111 12.7741 2.06011C14.7541 2.29111 15.9571 3.22611 15.4621 4.15011C15.0621 4.88811 13.4621 5.35611 11.8781 5.15011C11.1071 5.12612 10.356 4.8999 9.70007 4.49411"
        stroke={iconColor ?? '#546170'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.9998 5.76392C16.9998 8.52292 15.5398 13.6339 11.8438 13.6339C8.14781 13.6339 7.00781 8.52292 7.00781 5.76392"
        stroke={iconColor ?? '#546170'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.8551 14.8C12.3062 17.1854 12.2691 19.6374 11.7461 22.008"
        stroke={iconColor ?? '#546170'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.252 18.162C12.5398 17.423 13.087 16.8143 13.7912 16.4496C14.4954 16.085 15.3084 15.9895 16.078 16.181C16.078 16.181 16.789 19.688 12.494 19.592"
        stroke={iconColor ?? '#546170'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9761 16.5389C11.5324 15.8819 10.8645 15.409 10.0973 15.2088C9.33018 15.0085 8.51637 15.0946 7.80811 15.4509C7.80811 15.4509 7.8871 19.0289 12.0561 17.9869"
        stroke={iconColor ?? '#1A2C40'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  return (
    <IconWrap
      icon={icon}
      color={color}
      width={width}
      viewBoxWidth={viewBoxWidth}
      viewBoxHeight={viewBoxHeight}
      style={style}
      {...restProps}
    />
  );
};

export default IconSupplierFlorist;
