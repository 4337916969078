import React from 'react';
import { FelaCSS } from '../../components/fela/flowtypes';
import { IStylingProps } from '../../themes/types';
import { TColor } from '../../types';
import IconWrap from './icon-wrap';

interface IProps extends IStylingProps {
  id?: string;
  color?: TColor;
  width?: number;
  style?: FelaCSS;
}

interface IconTypes {
  iconColor: string;
  width: number;
  height: number;
}

const IconSupplierMenswear = ({ id, color, width = 48, style, ...restProps }: IProps) => {
  const viewBoxWidth = 24;
  const viewBoxHeight = 24;

  const icon = ({ iconColor, width, height }: IconTypes) => (
    <svg
      id={id}
      viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
      width={`${width}px`}
      height={`${height}px`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.2362 6.35889L12.0002 5.26789L14.7632 6.35889C15.4372 6.35889 15.4372 2.35889 14.7632 2.35889L12.0002 3.45089L9.2362 2.35889C8.5632 2.35889 8.5632 6.35889 9.2362 6.35889Z"
        stroke={iconColor ?? '#546170'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.2279 17.155L6.96189 6.63599C5.2814 6.98849 3.80797 7.99007 2.86189 9.42299C2.82061 9.48282 2.79286 9.55095 2.7806 9.6226C2.76834 9.69426 2.77185 9.76773 2.79089 9.83789C2.80993 9.90805 2.84404 9.97322 2.89084 10.0288C2.93765 10.0845 2.99602 10.1292 3.06189 10.16L5.32589 11.224C5.38564 11.2521 5.43923 11.2917 5.48357 11.3406C5.52792 11.3895 5.56215 11.4467 5.58427 11.5089C5.6064 11.5711 5.61599 11.6371 5.61249 11.703C5.60899 11.7689 5.59248 11.8335 5.56389 11.893L4.46389 14.175C4.43011 14.2455 4.41333 14.3229 4.4149 14.4011C4.41646 14.4792 4.43632 14.5559 4.47289 14.625L9.32789 21.641C9.84013 21.0276 10.1908 20.2959 10.348 19.5124C10.5052 18.7289 10.4639 17.9185 10.2279 17.155V17.155Z"
        stroke={iconColor ?? '#546170'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.666 21.641L19.521 14.625C19.5575 14.5559 19.5774 14.4792 19.579 14.4011C19.5805 14.3229 19.5638 14.2455 19.53 14.175L18.43 11.893C18.4014 11.8335 18.3849 11.7689 18.3814 11.703C18.3779 11.6371 18.3875 11.5711 18.4096 11.5089C18.4317 11.4467 18.466 11.3895 18.5103 11.3406C18.5546 11.2917 18.6082 11.2521 18.668 11.224L20.932 10.16C20.9979 10.1292 21.0562 10.0845 21.103 10.0288C21.1498 9.97322 21.1839 9.90805 21.203 9.83789C21.222 9.76773 21.2255 9.69426 21.2133 9.6226C21.201 9.55095 21.1733 9.48282 21.132 9.42299C20.1859 7.99007 18.7125 6.98849 17.032 6.63599L13.772 17.155C13.5352 17.918 13.4929 18.7281 13.649 19.5116C13.8052 20.2951 14.1548 21.0271 14.666 21.641V21.641Z"
        stroke={iconColor ?? '#546170'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.25 9.3999C12.25 9.3336 12.2237 9.27001 12.1768 9.22313C12.1299 9.17624 12.0663 9.1499 12 9.1499"
        stroke={iconColor ?? '#546170'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.75 9.3999C11.75 9.46621 11.7763 9.5298 11.8232 9.57668C11.8701 9.62356 11.9337 9.6499 12 9.6499C12.0663 9.6499 12.1299 9.62356 12.1768 9.57668C12.2237 9.5298 12.25 9.46621 12.25 9.3999"
        stroke={iconColor ?? '#1A2C40'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 9.15308C11.9337 9.15308 11.8701 9.17942 11.8232 9.2263C11.7763 9.27318 11.75 9.33677 11.75 9.40308"
        stroke={iconColor ?? '#1A2C40'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.25 13.6411C12.25 13.5748 12.2237 13.5112 12.1768 13.4643C12.1299 13.4175 12.0663 13.3911 12 13.3911"
        stroke={iconColor ?? '#1A2C40'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.75 13.6411C11.75 13.7074 11.7763 13.771 11.8232 13.8179C11.8701 13.8648 11.9337 13.8911 12 13.8911C12.0663 13.8911 12.1299 13.8648 12.1768 13.8179C12.2237 13.771 12.25 13.7074 12.25 13.6411"
        stroke={iconColor ?? '#1A2C40'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 13.3911C11.9337 13.3911 11.8701 13.4175 11.8232 13.4643C11.7763 13.5112 11.75 13.5748 11.75 13.6411"
        stroke={iconColor ?? '#1A2C40'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  return (
    <IconWrap
      icon={icon}
      color={color}
      width={width}
      viewBoxWidth={viewBoxWidth}
      viewBoxHeight={viewBoxHeight}
      style={style}
      {...restProps}
    />
  );
};

export default IconSupplierMenswear;
