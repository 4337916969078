import React from 'react';
import { FelaCSS } from '../../components/fela/flowtypes';
import { IStylingProps } from '../../themes/types';
import { TColor } from '../../types';
import IconWrap from './icon-wrap';

interface IProps extends IStylingProps {
  id?: string;
  color?: TColor;
  width?: number;
  style?: FelaCSS;
}

interface IconTypes {
  iconColor: string;
  width: number;
  height: number;
}

const IconSupplierHairBeauty = ({ id, color, width = 48, style, ...restProps }: IProps) => {
  const viewBoxWidth = 24;
  const viewBoxHeight = 24;

  const icon = ({ iconColor, width, height }: IconTypes) => (
    <svg
      id={id}
      viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
      width={`${width}px`}
      height={`${height}px`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.31201 6.68789C7.31201 6.68789 12 5.53589 12 3.23389"
        stroke={iconColor ?? '#546170'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.687 6.68789C16.687 6.68789 12 5.53589 12 3.23389"
        stroke={iconColor ?? '#546170'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.58789 4.967C7.58789 3.052 9.56289 1.5 11.9999 1.5C14.4369 1.5 16.4119 3.052 16.4119 4.967"
        stroke={iconColor ?? '#546170'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.58789 4.967C7.58789 3.052 9.56289 1.5 11.9999 1.5C14.4369 1.5 16.4119 3.052 16.4119 4.967"
        stroke={iconColor ?? '#546170'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.7998 14.369C17.8654 14.3539 17.9325 14.3459 17.9998 14.345C18.3322 14.3745 18.6396 14.5338 18.8554 14.7884C19.0712 15.0429 19.1781 15.3722 19.1528 15.705C19.1778 16.0376 19.0708 16.3666 18.855 16.6209C18.6393 16.8753 18.332 17.0344 17.9998 17.064C17.7836 17.0628 17.5735 16.9927 17.3998 16.864C16.5478 19.654 13.7688 22.464 11.9998 22.464C10.2308 22.464 7.45582 19.652 6.60482 16.864C6.43099 16.9924 6.22093 17.0624 6.00482 17.064C5.6726 17.0344 5.36538 16.8753 5.1496 16.6209C4.93383 16.3666 4.82685 16.0376 4.85182 15.705C4.82652 15.373 4.93269 15.0444 5.14744 14.79C5.36219 14.5356 5.66831 14.3758 5.99982 14.345C6.06714 14.3459 6.13418 14.3539 6.19982 14.369"
        stroke={iconColor ?? '#1A2C40'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.1582 18.6799C10.3643 18.9739 10.641 19.2113 10.9629 19.3703C11.2848 19.5293 11.6415 19.6049 12.0002 19.5899C12.3589 19.6049 12.7156 19.5293 13.0375 19.3703C13.3594 19.2113 13.6361 18.9739 13.8422 18.6799"
        stroke={iconColor ?? '#1A2C40'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.119 13.051C19.1677 12.9603 19.2127 12.8663 19.254 12.769C19.4121 12.4141 19.4958 12.0305 19.5 11.642C19.5 9.15301 16.142 7.13501 12 7.13501C7.858 7.13501 4.5 9.15301 4.5 11.642C4.50415 12.0305 4.58788 12.4141 4.746 12.769"
        stroke={iconColor ?? '#1A2C40'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.82401 18.5391C5.07801 19.2191 4.78367 19.7474 4.94101 20.1241C5.10401 20.5141 5.66101 20.5241 5.82401 20.9161C5.98701 21.3081 5.68701 21.8161 4.94101 22.5001"
        stroke={iconColor ?? '#1A2C40'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.1759 18.5391C18.9219 19.2191 19.2163 19.7474 19.0589 20.1241C18.8959 20.5141 18.3389 20.5241 18.1759 20.9161C18.0129 21.3081 18.3129 21.8161 19.0589 22.5001"
        stroke={iconColor ?? '#1A2C40'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.1191 13.051C18.2465 13.1357 17.3615 13.1783 16.4641 13.179C8.71415 13.179 8.22915 9.91602 8.22915 9.91602C8.22915 9.91602 8.39215 12.507 6.57715 12.507"
        stroke={iconColor ?? '#1A2C40'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5 14.5V15.5"
        stroke={iconColor ?? '#1A2C40'}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M14.5 14.5V15.5"
        stroke={iconColor ?? '#1A2C40'}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );

  return (
    <IconWrap
      icon={icon}
      color={color}
      width={width}
      viewBoxWidth={viewBoxWidth}
      viewBoxHeight={viewBoxHeight}
      style={style}
      {...restProps}
    />
  );
};

export default IconSupplierHairBeauty;
