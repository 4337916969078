import React from 'react';
import { FelaCSS } from '../../components/fela/flowtypes';
import { IStylingProps } from '../../themes/types';
import { TColor } from '../../types';
import IconWrap from './icon-wrap';

interface IProps extends IStylingProps {
  id?: string;
  color?: TColor;
  width?: number;
  style?: FelaCSS;
}

interface IconTypes {
  iconColor: string;
  width: number;
  height: number;
}

const IconSupplierMusic = ({ id, color, width = 48, style, ...restProps }: IProps) => {
  const viewBoxWidth = 24;
  const viewBoxHeight = 24;

  const icon = ({ iconColor, width, height }: IconTypes) => (
    <svg
      id={id}
      viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
      width={`${width}px`}
      height={`${height}px`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.6272 14.0779C19.6582 12.4079 21.6872 9.96489 21.6872 7.24789C21.6872 4.94789 19.8252 2.66089 17.5272 2.66089C16.7035 2.66248 15.8988 2.90862 15.2152 3.36812C14.5315 3.82762 13.9998 4.47977 13.6872 5.24189C13.3742 4.4789 12.8414 3.82618 12.1567 3.36662C11.4719 2.90707 10.6659 2.66142 9.84122 2.66089C7.03322 2.66089 4.87222 6.09189 5.98522 8.77289"
        stroke={iconColor ?? '#1A2C40'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.31299 19.4641C7.31299 20.5001 6.19399 21.3391 4.81299 21.3391C3.43199 21.3391 2.31299 20.5001 2.31299 19.4641C2.31299 18.4281 3.43299 17.5891 4.81299 17.5891C5.13696 17.5884 5.45922 17.6362 5.76899 17.7311"
        stroke={iconColor ?? '#546170'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.31299 19.464V10.344L15.313 9.34399"
        stroke={iconColor ?? '#546170'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.31299 13.3439L13.775 12.5359"
        stroke={iconColor ?? '#546170'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.313 18.089C15.313 19.125 14.194 19.964 12.813 19.964C11.432 19.964 10.313 19.125 10.313 18.089C10.313 16.796 12.059 15.809 13.879 16.38"
        stroke={iconColor ?? '#546170'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.313 18.089V9.34399"
        stroke={iconColor ?? '#1A2C40'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  return (
    <IconWrap
      icon={icon}
      color={color}
      width={width}
      viewBoxWidth={viewBoxWidth}
      viewBoxHeight={viewBoxHeight}
      style={style}
      {...restProps}
    />
  );
};

export default IconSupplierMusic;
