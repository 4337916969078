import React from 'react';
import { FelaCSS } from '../../components/fela/flowtypes';
import { IStylingProps } from '../../themes/types';
import { TColor } from '../../types';
import IconWrap from './icon-wrap';

interface IProps extends IStylingProps {
  id?: string;
  color?: TColor;
  width?: number;
  style?: FelaCSS;
}

interface IconTypes {
  iconColor: string;
  width: number;
  height: number;
}

const IconSupplierJeweller = ({ id, color, width = 48, style, ...restProps }: IProps) => {
  const viewBoxWidth = 24;
  const viewBoxHeight = 24;

  const icon = ({ iconColor, width, height }: IconTypes) => (
    <svg
      id={id}
      viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
      width={`${width}px`}
      height={`${height}px`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.61914 7.5L9.07914 5.5H14.9211L16.3811 7.5L12.0001 12.801L7.61914 7.5Z"
        stroke={iconColor ?? '#546170'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.25422 10.925C6.29246 11.8678 5.63324 13.0755 5.3606 14.3944C5.08797 15.7132 5.21427 17.0834 5.72342 18.3302C6.23256 19.577 7.10148 20.6439 8.21937 21.3949C9.33726 22.1459 10.6535 22.5469 12.0002 22.5469C13.347 22.5469 14.6632 22.1459 15.7811 21.3949C16.899 20.6439 17.7679 19.577 18.277 18.3302C18.7862 17.0834 18.9125 15.7132 18.6398 14.3944C18.3672 13.0755 17.708 11.8678 16.7462 10.925"
        stroke={iconColor ?? '#546170'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.73291 3.92896L6.20791 5.40496"
        stroke={iconColor ?? '#546170'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 1.53809V3.62409"
        stroke={iconColor ?? '#546170'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.792 5.40496L19.267 3.92896"
        stroke={iconColor ?? '#1A2C40'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5001 7.5H7.61914"
        stroke={iconColor ?? '#1A2C40'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  return (
    <IconWrap
      icon={icon}
      color={color}
      width={width}
      viewBoxWidth={viewBoxWidth}
      viewBoxHeight={viewBoxHeight}
      style={style}
      {...restProps}
    />
  );
};

export default IconSupplierJeweller;
