import React from 'react';
import { FelaCSS } from '../../components/fela/flowtypes';
import { IStylingProps } from '../../themes/types';
import { TColor } from '../../types';
import IconWrap from './icon-wrap';

interface IProps extends IStylingProps {
  id?: string;
  color?: TColor;
  width?: number;
  style?: FelaCSS;
  strokeWidth?: number;
}

interface IconTypes {
  iconColor: string;
  width: number;
  height: number;
}

const IconSupplierStationery = ({
  id,
  color,
  width = 48,
  strokeWidth = 1,
  style,
  ...restProps
}: IProps) => {
  const viewBoxWidth = 24;
  const viewBoxHeight = 24;

  const icon = ({ iconColor, width, height }: IconTypes) => (
    <svg
      id={id}
      viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
      width={`${width}px`}
      height={`${height}px`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.9202 10.0759C13.5083 10.0768 13.1059 10.2 12.7641 10.4298C12.4223 10.6597 12.1564 10.9858 12.0002 11.3669C11.8439 10.9853 11.5776 10.6587 11.2351 10.4288C10.8927 10.1989 10.4896 10.0761 10.0772 10.0759C9.50539 10.1251 8.97339 10.3889 8.5881 10.8143C8.20281 11.2396 7.99277 11.7951 8.00019 12.3689C8.00019 14.8809 11.4672 16.9239 12.0002 16.9239C12.5332 16.9239 16.0002 14.8809 16.0002 12.3689C16.0075 11.7946 15.7971 11.2388 15.4112 10.8133C15.0253 10.3879 14.4925 10.1244 13.9202 10.0759Z"
        stroke={iconColor ?? '#1A2C40'}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.5 5.5H5.5C4.94772 5.5 4.5 5.94772 4.5 6.5V20.5C4.5 21.0523 4.94772 21.5 5.5 21.5H18.5C19.0523 21.5 19.5 21.0523 19.5 20.5V6.5C19.5 5.94772 19.0523 5.5 18.5 5.5Z"
        stroke={iconColor ?? '#546170'}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.3501 5.49994L16.1841 1.93894C16.3343 1.88889 16.4943 1.87525 16.6509 1.89913C16.8075 1.92301 16.9561 1.98374 17.0846 2.0763C17.2131 2.16887 17.3178 2.29063 17.39 2.43156C17.4622 2.57249 17.5 2.72857 17.5001 2.88694V3.49994"
        stroke={iconColor ?? '#546170'}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  return (
    <IconWrap
      icon={icon}
      color={color}
      width={width}
      viewBoxWidth={viewBoxWidth}
      viewBoxHeight={viewBoxHeight}
      style={style}
      {...restProps}
    />
  );
};

export default IconSupplierStationery;
