import React from 'react';
import { FelaCSS } from '../../components/fela/flowtypes';
import { IStylingProps } from '../../themes/types';
import { TColor } from '../../types';
import IconWrap from './icon-wrap';

interface IProps extends IStylingProps {
  id?: string;
  color?: TColor;
  width?: number;
  style?: FelaCSS;
}

interface IconTypes {
  iconColor: string;
  width: number;
  height: number;
}

const IconSupplierCaterer = ({ id, color, width = 48, style, ...restProps }: IProps) => {
  const viewBoxWidth = 24;
  const viewBoxHeight = 24;

  const icon = ({ iconColor, width, height }: IconTypes) => (
    <svg
      id={id}
      viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
      width={`${width}px`}
      height={`${height}px`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.26807 13.3309C3.29254 11.0565 4.21323 8.88351 5.83023 7.28381C7.44723 5.68412 9.62999 4.78687 11.9046 4.78687C14.1791 4.78687 16.3619 5.68412 17.9789 7.28381C19.5959 8.88351 20.5166 11.0565 20.5411 13.3309"
        stroke={iconColor ?? '#546170'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.26807 13.3309C3.29254 11.0565 4.21323 8.88351 5.83023 7.28381C7.44723 5.68412 9.62999 4.78687 11.9046 4.78687C14.1791 4.78687 16.3619 5.68412 17.9789 7.28381C19.5959 8.88351 20.5166 11.0565 20.5411 13.3309"
        stroke={iconColor ?? '#546170'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.2678 7.31201C9.23265 7.59583 8.28785 8.14091 7.52401 8.89494C6.76017 9.64897 6.20293 10.5867 5.90576 11.618"
        stroke={iconColor ?? '#546170'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.90381 15.1499H21.9038"
        stroke={iconColor ?? '#546170'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.4649 3.60001C10.4598 3.40778 10.4933 3.21649 10.5633 3.03741C10.6334 2.85833 10.7386 2.6951 10.8728 2.55734C11.007 2.41959 11.1674 2.3101 11.3445 2.23534C11.5217 2.16059 11.7121 2.12207 11.9044 2.12207C12.0967 2.12207 12.287 2.16059 12.4642 2.23534C12.6413 2.3101 12.8017 2.41959 12.9359 2.55734C13.0701 2.6951 13.1753 2.85833 13.2454 3.03741C13.3154 3.21649 13.3489 3.40778 13.3439 3.60001"
        stroke={iconColor ?? '#1A2C40'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.58691 17.4879L7.77291 16.9769C9.3678 16.6773 11.0165 16.8854 12.4869 17.5719C13.8769 18.3329 12.5309 19.8859 9.33191 18.7719"
        stroke={iconColor ?? '#1A2C40'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.47217 20.972C8.05817 20.15 8.80717 23.012 11.8032 22.113C20.1262 19.613 24.3522 15.577 14.4832 18.022"
        stroke={iconColor ?? '#1A2C40'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  return (
    <IconWrap
      icon={icon}
      color={color}
      width={width}
      viewBoxWidth={viewBoxWidth}
      viewBoxHeight={viewBoxHeight}
      style={style}
      {...restProps}
    />
  );
};

export default IconSupplierCaterer;
