/**
 * Converts number of days/hours/minutes etc. to milliseconds
 */
export const toMillis = (count: number, from: 'week' | 'day' | 'hour' | 'min' | 'sec') => {
  const fromSec = count * 1000;
  const fromMin = fromSec * 60;
  const fromHour = fromMin * 60;
  const fromDay = fromHour * 24;
  const fromWeek = fromDay * 7;

  switch (from) {
    case 'sec':
      return fromSec;
    case 'min':
      return fromMin;
    case 'hour':
      return fromHour;
    case 'day':
      return fromDay;
    case 'week':
      return fromWeek;
  }
};

/**
 * Converts number of days/hours/minutes etc. to seconds
 */
export const toSeconds = (...args: Parameters<typeof toMillis>) => toMillis(...args) / 1000;
