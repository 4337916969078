import React from 'react';
import { FelaCSS } from '../../components/fela/flowtypes';
import { IStylingProps } from '../../themes/types';
import { TColor } from '../../types';
import IconWrap from './icon-wrap';

interface IProps extends IStylingProps {
  id?: string;
  color?: TColor;
  width?: number;
  style?: FelaCSS;
}

interface IconTypes {
  iconColor: string;
  width: number;
  height: number;
}

const IconSupplierCakeMaker = ({ id, color, width = 48, style, ...restProps }: IProps) => {
  const viewBoxWidth = 24;
  const viewBoxHeight = 24;

  const icon = ({ iconColor, width, height }: IconTypes) => (
    <svg
      id={id}
      viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
      width={`${width}px`}
      height={`${height}px`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.4002 2C13.0999 2.00089 12.8065 2.09087 12.5573 2.25855C12.3081 2.42624 12.1142 2.66409 12.0002 2.942C11.886 2.66424 11.6921 2.42654 11.443 2.25889C11.1938 2.09124 10.9005 2.00115 10.6002 2C10.182 2.03488 9.79248 2.22699 9.51024 2.53763C9.228 2.84826 9.07398 3.25433 9.07923 3.674C9.07923 5.509 11.6112 7 12.0002 7C12.3892 7 14.9212 5.509 14.9212 3.674C14.9265 3.25433 14.7725 2.84826 14.4902 2.53763C14.208 2.22699 13.8185 2.03488 13.4002 2V2Z"
        stroke={iconColor ?? '#1A2C40'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M2.5 22H21.5" stroke="#546170" strokeMiterlimit="10" strokeLinecap="round" />
      <path
        d="M18.7461 17.1001C17.6231 17.1001 17.6231 18.9001 16.5011 18.9001C15.3791 18.9001 15.3771 17.1001 14.2541 17.1001C13.1311 17.1001 13.1291 18.9001 12.0001 18.9001C10.8711 18.9001 10.8751 17.1001 9.74912 17.1001C8.62312 17.1001 8.62812 18.9001 7.50012 18.9001C6.37212 18.9001 6.37212 17.1001 5.24512 17.1001"
        stroke={iconColor ?? '#546170'}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M3.5 22V17C3.5 16.4696 3.71071 15.9609 4.08579 15.5858C4.46086 15.2107 4.96957 15 5.5 15H18.5C19.0304 15 19.5391 15.2107 19.9142 15.5858C20.2893 15.9609 20.5 16.4696 20.5 17V22"
        stroke={iconColor ?? '#546170'}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M7.49316 10.571C8.61616 10.571 8.61616 12.371 9.74016 12.371C10.8642 12.371 10.8652 10.571 11.9902 10.571C13.1152 10.571 13.1152 12.371 14.2402 12.371C15.3652 12.371 15.3662 10.571 16.4912 10.571"
        stroke={iconColor ?? '#546170'}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M5.5 13.4709V10.4709C5.5 9.94051 5.71071 9.43181 6.08579 9.05673C6.46086 8.68166 6.96957 8.47095 7.5 8.47095H16.5C17.0304 8.47095 17.5391 8.68166 17.9142 9.05673C18.2893 9.43181 18.5 9.94051 18.5 10.4709V13.4709"
        stroke={iconColor ?? '#1A2C40'}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );

  return (
    <IconWrap
      icon={icon}
      color={color}
      width={width}
      viewBoxWidth={viewBoxWidth}
      viewBoxHeight={viewBoxHeight}
      style={style}
      {...restProps}
    />
  );
};

export default IconSupplierCakeMaker;
