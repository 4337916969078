import React from 'react';
import { FelaCSS } from '../../components/fela/flowtypes';
import { IStylingProps } from '../../themes/types';
import { TColor } from '../../types';
import IconWrap from './icon-wrap';

interface IProps extends IStylingProps {
  id?: string;
  color?: TColor;
  width?: number;
  style?: FelaCSS;
}

interface IconTypes {
  iconColor: string;
  width: number;
  height: number;
}

const IconSupplierVideographer = ({ id, color, width = 48, style, ...restProps }: IProps) => {
  const viewBoxWidth = 24;
  const viewBoxHeight = 24;

  const icon = ({ iconColor, width, height }: IconTypes) => (
    <svg
      id={id}
      viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
      width={`${width}px`}
      height={`${height}px`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.5 3.5H4.5C3.39543 3.5 2.5 4.39543 2.5 5.5V11.5C2.5 12.6046 3.39543 13.5 4.5 13.5H14.5C15.6046 13.5 16.5 12.6046 16.5 11.5V5.5C16.5 4.39543 15.6046 3.5 14.5 3.5Z"
        stroke={iconColor ?? '#546170'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 6.5L21.5 4.5V12.5L18 10.5"
        stroke={iconColor ?? '#546170'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 15.5L5.5 21.5"
        stroke={iconColor ?? '#546170'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 15.5L13.5 21.5"
        stroke={iconColor ?? '#546170'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  return (
    <IconWrap
      icon={icon}
      color={color}
      width={width}
      viewBoxWidth={viewBoxWidth}
      viewBoxHeight={viewBoxHeight}
      style={style}
      {...restProps}
    />
  );
};

export default IconSupplierVideographer;
